import React from 'react';

import { NavBar } from '../components/navbar';
import { Footer } from '@apd/footer';
import { BrowserRouter } from 'react-router-dom';
import config from '../constants/config';
import TagManager from 'react-gtm-module'
import { CssMain, CssBody, GlobalStyle } from './App-Style';
import Router from '../router/router';
import navigationData from '../constants/navigation';
import { AppProvider } from '../context';

const tagManagerArgs = {
	gtmId: 'GTM-M6BKDHC'
}

TagManager.initialize(tagManagerArgs)

const App = ({ props }) => {
  window.dataLayer.push({
  	event: 'pageview'
  })

  return (
    <BrowserRouter>
      <AppProvider>
        <GlobalStyle />
        <ContentWrapper {...props} />
      </AppProvider>
    </BrowserRouter>
  );
};

const ContentWrapper = () => (
  <CssBody>
    <NavBar data={navigationData} name='' />
    <CssMain>
      <Router />
    </CssMain>
    <Footer appName='Estudo Pobreza Energética' appVersion={config.app_version} />
  </CssBody>
);

export default App;
