import React from 'react';

import { CssItem, CssNavLink } from './MenuItem-style';

const MenuItem = ({ menu, index }) => (
  <CssItem key={index}>
    <CssNavLink to={menu.route} exact activeClassName='active'>
      {menu.name}
    </CssNavLink>
  </CssItem>
);

export default MenuItem;
