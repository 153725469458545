import React from 'react';
import config from '../../constants/config';
import {configTemplateCircle, optionsDoughnut} from './options'

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    ArcElement,
    Tooltip,
    Legend
  } from 'chart.js';

  import { Doughnut } from 'react-chartjs-2';

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    ArcElement,
    Tooltip,
    Legend
  );
  


export const FinanceiraGraph = () => (
  <Doughnut
  data={{
    labels: config.importJson.financeira.data.map((e) => e[0]),
    datasets: [
      {
        label: '',
        data: config.importJson.financeira.data.map((e) => 100 * e[1]),
        ...configTemplateCircle
      }
    ]
  }}
  options={optionsDoughnut}
/>
);
