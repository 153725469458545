import React from 'react';
import ApdMap from '@apd/apd-leaflet';
import { CssMapWrapper } from '../screens/Global-style';
import config from '../constants/config';
import { BuildPolygon } from './BuildPolygon';

import { valueKeys } from '../components/helper';

export const MapTotal = ({ data, answer, question }) => {
  if (!answer || !data || !data[answer]) return null;

  const stringEuros = '(€)'
  const isValueMoney = question.toLowerCase().includes(stringEuros.toLowerCase())
  const valueSign = isValueMoney ? "€" : "%" ; 

  const keys = valueKeys(data, answer);
  const polyRawData = config.geojson.cityGeoJson.features;

  return (
    <CssMapWrapper>
      <ApdMap zoom={config.map.zoom} center={config.map.center}>
        {keys.map((zoneName) => {
          const findPoly = polyRawData.find((x) => x.properties.title === zoneName);
          if (!findPoly) return null;

          const opacityVal = data[answer][zoneName];

          return (
            <BuildPolygon
              geoData={findPoly}
              opacity={opacityVal}
              name={zoneName}
              key={'BuildPolygon' + zoneName}
              isValueMoney={isValueMoney}
              valueSign={valueSign}
            />
          );
        })}
      </ApdMap>
    </CssMapWrapper>
  );
};
