import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const CssItem = styled.li`
  float: left;
`;

export const CssNavLink = styled(NavLink)`
  display: block;
  padding: ${({ theme: { size } }) => size.spacing_5 + ' ' + size.spacing_6};
  color: ${({ theme: { brandToken } }) => brandToken.colors.bgray_8};
  text-align: center;
  text-decoration: none;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  align-items: center;

  &.active {
    background: #cfdbe8;
    box-shadow: inset 0 -2px 0 #0e4d8d;
    color: ${({ theme: { brandToken } }) => brandToken.colors.apd_m};
  }
`;
