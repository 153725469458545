import React from 'react';
import config from '../../constants/config';
import {configTemplateBar, optionsBar} from './options'

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    ArcElement,
    Tooltip,
    Legend
  } from 'chart.js';

  import { Bar } from 'react-chartjs-2';

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    ArcElement,
    Tooltip,
    Legend
  );
  


export const RendimentoGraph = () => (
    <Bar
      options={optionsBar}
      data={{
        labels: [''],
        datasets: config.importJson.rendimento.data.map((e, index) => {
          return {
            label: e[0],
            data: [100 * e[1]],
            backgroundColor: configTemplateBar.backgroundColor[index],
          };
        })
      }}
    />
);
