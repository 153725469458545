
const colorArray = [
  '#3366cc',
  '#dc3912',
  '#ff9900',
  '#109618',
  '#990099',
  '#0099c6',
  '#dd4477',
  '#66aa00',
  '#b82e2e',
  '#316395',
  '#3366cc',
  '#994499',
  '#22aa99',
  '#aaaa11',
  '#6633cc',
  '#e67300',
  '#8b0707',
  '#651067',
  '#329262',
  '#5574a6',
  '#3b3eac',
  '#b77322',
  '#16d620',
  '#b91383',
  '#f4359e',
  '#9c5935',
  '#a9c413',
  '#2a778d',
  '#668d1c',
  '#bea413',
  '#0c5922',
  '#743411'
];

const convertHexToRGBA = (hex, opacity) => {
  const tempHex = hex.replace('#', '');
  const r = parseInt(tempHex.substring(0, 2), 16);
  const g = parseInt(tempHex.substring(2, 4), 16);
  const b = parseInt(tempHex.substring(4, 6), 16);

  return `rgba(${r},${g},${b},${opacity / 100})`;
};

export const configTemplateCircle = {
  backgroundColor: colorArray.map(e => convertHexToRGBA(e, 60)),
  borderColor: colorArray.map(e => convertHexToRGBA(e, 60)),
  borderWidth: 1,
  cutout: "80%"
};

export const configTemplateBar = {
  backgroundColor: colorArray.map(e => convertHexToRGBA(e, 60)),
  borderColor: colorArray.map(e => convertHexToRGBA(e, 60)),
  borderWidth: 1
};

// BAR -----------------------------

export const barHeight = 125;

export const optionsBar = {
  responsive: true,
  maintainAspectRatio: true,
  scales: {
    y: {
      min: 0,
      ticks: {
        callback: function (value, index, values) {
          return value + ' %';
        }
      }
    }
  },
  plugins: {
    legend: {
      position: 'bottom',
      align: 'start',
      labels: {
        boxWidth: 12,
        boxHeight: 12,
        generateLabels: function (chart) {
          const data = chart.data;
          if (!data.labels.length && !data.datasets.length) return [];

          return data.datasets.map((label, i) => {
            const value = label.data[0].toFixed(0);
            const labelText = label.label;
            const meta = chart.getDatasetMeta(i);
            const style = meta.controller.getStyle(i);

            return {
              text: `(${value}%) ${labelText}`,
              fillStyle: style.backgroundColor,
              strokeStyle: style.borderColor,
              lineWidth: style.borderWidth,
              //   pointStyle: `<div>(${value}%) ${labelText}</div>`,
              hidden: meta.hidden,
              datasetIndex: i
            };
          });
        }
      },
      onClick(e, legendItem, legend) {
        const index = legendItem.datasetIndex;
        const ci = legend.chart;
        const meta = ci.getDatasetMeta(index);
        const setHidden = ci.data.datasets[index].hidden;
        meta.hidden = meta.hidden === null ? !setHidden : null;
        ci.update();
      }
    },
    tooltip: {
      callbacks: {
        label: function (context) {
          let label = context.dataset.label || '';
          if (context.parsed.y !== null) {
            label = `(${context.parsed.y.toFixed(0)}%) ${
              context.dataset.label
            }`;
          }
          return label;
        }
      }
    }
  }
};

// Doughnut --------------------
export const optionsDoughnut = ({valueSign}) =>  {

  return {
    radius: 100,
  plugins: {
    legend: {
      display: true,
      position: 'bottom',
      onClick: function (e, legendItem, legend) {
        let arc = legend.chart.getDatasetMeta(0).data[legendItem.index];
        arc.hidden = !arc.hidden ? true : false;
        this.chart.update();
      },
      labels: {
        boxWidth: 12,
        boxHeight: 12,
        generateLabels: function (chart) {
          const data = chart.data;
          if (!data.labels.length && !data.datasets.length) return [];

          return data.labels.map(function (label, i) {
            const meta = chart.getDatasetMeta(0);
            const ds = data.datasets[0];
            const fill = ds.backgroundColor[i];
            const stroke = ds.borderColor[i];
            const bw = ds.borderColor[i];

            return {
              text: `(${(ds.data[i]).toFixed(0)}${valueSign}) ${label}`,
              fillStyle: fill,
              strokeStyle: stroke,
              lineWidth: bw,
              hidden: isNaN(ds.data[i]) || meta.data[i].hidden,
              index: i
            };
          });
        }
      }
    },
    tooltip: {
      callbacks: {
        label: function (tooltipItem) {
          return `(${tooltipItem.raw.toFixed(0) }${valueSign}) ${tooltipItem.label}`;
        }
      }
    }
  },
  layout: {
    padding: {
      left: 0,
      right: 0,
      top: 0,
      bottom: 0
    }
  }
}};
