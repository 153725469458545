import styled from 'styled-components';

export const CssNavBar = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: fill-available;
  height: ${({ theme: { size } }) => size.layout_5};
  background: ${(props) => props.theme.colors.bg.navbar.default};
  display: flex;
  z-index: ${(props) => props.theme.zIndex.house};
  box-shadow: inset 0 -2px 0 ${(props) => props.theme.colors.border.navbar.default};
  overflow: hidden;
  font-family: Roboto;
`;
export const CssNavBarShadow = styled.div`
  height: ${(props) => props.theme.size.layout_5};
  /* background-color: orange; */
`;

export const CssUl = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

export const CssLogo = styled.div`
  padding-top: ${({ theme: { size } }) => size.spacing_5};
  padding-right: ${({ theme: { size } }) => size.spacing_2};
  padding-bottom: ${({ theme: { size } }) => size.spacing_5};
  padding-left: ${({ theme: { size } }) => size.spacing_6};
  & > svg {
    fill: ${(props) => props.theme.colors.bg.navbar.logo};
  }
  & .center {
    padding-top: 2px;
  }
`;

export const CssAppName = styled.div`
  float: left;
  display: block;
  padding-top: ${({ theme: { size } }) => size.spacing_5};
  padding-right: ${({ theme: { size } }) => size.spacing_6};
  padding-bottom: ${({ theme: { size } }) => size.spacing_5};
  padding-left: ${({ theme: { size } }) => size.spacing_2};
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: ${(props) => props.theme.colors.bg.navbar.logo};
`;
export const CssLinkWrapper = styled.div``;
export const NavbarWrapper = styled.div``;


