import React from 'react';
import { CssLogo } from './LogoSvg-style';
import config from '../../../../constants/config';

console.log(config.cityName)
const LogoPorto = () => (
  <CssLogo>
    {config.cityName === 'porto' ? 
    <img src={require('../porto.png')} height="39"/>
    :
    <img src={require('../lisboa.png')} height="39"/>
    }

  </CssLogo>
);

export default LogoPorto;
