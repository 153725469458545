import React from 'react';
import { Polygon, Tooltip } from 'react-leaflet';

const loop_map = (x, idx) =>
  x.map((item, Index) => {
    if (isNaN(item && item[0])) {
      return loop_map(item, Index);
    } else {
      return [item[1], item[0]];
    }
  });

export const BuildPolygon = ({
  geoData,
  opacity,
  name,
  valueSign,
  isValueMoney
}) => {

  const rawPoly = geoData.geometry.coordinates[0];
  const buildGeoPolygon = loop_map(rawPoly);

  const showValue = isValueMoney ? opacity : (100 * opacity).toFixed(0);
  const _fillOpacity = isValueMoney ? opacity/100 : opacity - 0.1;

  return (
    <Polygon
      key={name}
      pathOptions={{
        weight: 0.7,
        smoothFactor: 1,
        color: '#000000',
        opacity: 0.7,
        fillColor: '#000F9F',
        fillOpacity: _fillOpacity
      }}
      positions={buildGeoPolygon}
    >
      <Tooltip>
        (
          {showValue}
          {valueSign}
        ) {name}
      </Tooltip>
    </Polygon>
  );
};
