import styled from 'styled-components';

export const CssWrapper = styled.div`
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
`;

export const CssGridWrapper = styled.div`
  margin-top: 16px;
  margin-bottom: 16px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(1, 1fr);
  grid-column-gap: 16px;
  grid-row-gap: 16px;
`;

export const CssGraphWrapper = styled.div`
  margin-top: 16px;
  margin-bottom: 16px;
`;

export const CssTotalKPIsWrapper = styled.div`
  flex-wrap: wrap;
  margin-right: 12px;
  margin-top: 22px;
  max-width: 200px;
  min-width: 100px;
  > div > div {
    line-height: 16px;
    font-size: 16px;
  }
  > div > div:first-child {
    line-height: 22px;
    font-size: 22px;
  }
`;

export const CssTotalWrapper = styled.div`
  margin-top: 16px;
  display: flex;
  flex-wrap: wrap;
`;

export const CssMapWrapper = styled.div`
  margin-top: 16px;
  position: relative;
  z-index: 1;
  height: 500px;
  > .leaflet-container {
    display: flex;
    flex: auto;
  }
`;
