import React from 'react';
import { CssWrapper, CssGridWrapper, CssGraphWrapper } from '../Global-style';
import { PageHeader } from '@apd/page-header';
import Tile from '@apd/tile';
import { T_Body } from '@apd/typography';
import config from '../../constants/config';

import { FreguesiaGraph } from '../../components/graphs/FreguesiaGraph';
import { GrupoEtarioGraph } from '../../components/graphs/GrupoEtarioGraph';
import { GeneroGraph } from '../../components/graphs/GeneroGraph';
import { EscolaridadeGraph } from '../../components/graphs/EscolaridadeGraph';
import { TrabalhoGraph } from '../../components/graphs/TrabalhoGraph';
import { FinanceiraGraph } from '../../components/graphs/FinanceiraGraph';
import { RendimentoGraph } from '../../components/graphs/RendimentoGraph';
import { HabitacaoGraph } from '../../components/graphs/HabitacaoGraph';
import { SituacaoHabitacaoGraph } from '../../components/graphs/SituacaoHabitacaoGraph';

const SociodemograficaScreen = () => {
  return (
    <CssWrapper>
      <PageHeader pageTitle='Sociodemografia' />
      <Tile header='Inquérito à pobreza energética'>
        <T_Body>
        {config.text.sociodemografica.text}
        </T_Body>
      </Tile>

      <CssGraphWrapper>
        <Tile header='Freguesia'>
          <FreguesiaGraph />
        </Tile>
      </CssGraphWrapper>

      <CssGridWrapper>
        <Tile header='Grupo etário'>
          <GrupoEtarioGraph />
        </Tile>

        <Tile header='Género'>
          <GeneroGraph />
        </Tile>
      </CssGridWrapper>

      <CssGraphWrapper>
        <Tile header='Grau de escolaridade'>
          <EscolaridadeGraph />
        </Tile>
      </CssGraphWrapper>

      <CssGridWrapper>
        <Tile header='Situação perante o trabalho'>
          <TrabalhoGraph />
        </Tile>
        <Tile header='Situação financeira'>
          <FinanceiraGraph />
        </Tile>
      </CssGridWrapper>

      <CssGraphWrapper>
        <Tile header='Rendimento médio mensal líquido do agregado doméstico'>
          <RendimentoGraph />
        </Tile>
      </CssGraphWrapper>
      <CssGraphWrapper>
      <Tile header='Assoalhadas da habitação'>
        <HabitacaoGraph />
      </Tile>
      </CssGraphWrapper>
      <CssGridWrapper>
        <Tile header='Situação perante a habitação'>
          <SituacaoHabitacaoGraph />
        </Tile>
      </CssGridWrapper>
    </CssWrapper>
  );
};

export default SociodemograficaScreen;
