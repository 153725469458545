import React from 'react';
import { configTemplateCircle, optionsDoughnut } from './options';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  ArcElement,
  Tooltip,
  Legend
} from 'chart.js';

import { Doughnut } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  ArcElement,
  Tooltip,
  Legend
);

export const ResultTotalsGraph = ({ data, question }) => {
  if (!data) return null;
  const keys = Object.keys(data);
  console.log(question)
  const stringEuros = '(€)'
  const isValueMoney = question.toLowerCase().includes(stringEuros.toLowerCase())
  const valueSign = isValueMoney ? "€" : "%" ; 

  return (
    <Doughnut
      key={"ResultTotalsGraph"+keys}
      data={{
        labels: keys.map((e) => data[e].Resposta),
        datasets: [
          {
            label: '',
            data: keys.map((e) => {
              let value = data[e].Total;
              if (!isValueMoney) value = 100 * data[e].Total;
              return value

            }),
            ...configTemplateCircle
          }
        ]
      }}
      options={optionsDoughnut({valueSign})}
    />
  );
};
