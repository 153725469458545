// const queryParams = new URLSearchParams(window.location.search);
// let city = null

// if (!queryParams.get('city')) {
//   city === "porto"
//   let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?city=porto';
//   window.history.pushState({path:newurl},'',newurl);
// } else {
//   city = queryParams.get('city');
// }

// export default [
//   {
//     name: 'Sociodemográfica',
//     route: `/sociodemografica?city=${city}`
//   },
//   {
//     name: 'Resultados',
//     route: `/resultados?city=${city}`
//   }
// ];

export default [
  {
    name: 'Sociodemografia',
    route: `/sociodemografia`
  },
  {
    name: 'Resultados',
    route: `/resultados`
  }
];
