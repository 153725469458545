import React from 'react';
import { Kpi } from '@apd/kpi';
import { valueKeys } from '../components/helper';
import { CssTotalKPIsWrapper } from '../../src/screens/Global-style';

export const TotalKpi = ({ data, answer, question }) => {
  if (!data || !answer || !data[answer]) return null;
  const keys = valueKeys(data, answer);

  const stringEuros = '(€)'
  const isValueMoney = question.toLowerCase().includes(stringEuros.toLowerCase())
  const valueSign = isValueMoney ? "€" : "%" ; 

  return keys.map((item) => {

    const value = isValueMoney ? (data[answer][item]) : (100 * data[answer][item]);
    const name = item;
    return (
      <CssTotalKPIsWrapper
        key={`key_${name}`}
        // onMouseEnter={() => console.log('enter')}
        // onMouseLeave={() => console.log('leave')}
      >
        <Kpi value={`${value.toFixed(0)}${valueSign}`} label={name} />
      </CssTotalKPIsWrapper>
    );
  });
};
