import React, { useState, useEffect, useMemo } from 'react';
import { MenuItem, LogoPorto } from './components';
import {
  CssNavBar,
  CssUl,
  NavbarWrapper,
  CssAppName,
  CssNavBarShadow,
  CssLinkWrapper
} from './NavBar-style';

const renderMenuItems = (items) => {
  // The Navbar suport only 2 levels {basic and one level dropdown}
  return items.map((menu, index) => {
    if (!menu.name)
      console.error(
        'Menu JSON data [{name: string, route: string},{name: string, items: [{...}]}]'
      );
    if (!menu.items) return MenuItem({ menu, index });
    if (menu.items) dropdownMenuItem(menu);
    return null;
  });
};

const dropdownMenuItem = (item) => {
  // console.log("menu", item)
};

const NavBar = (props) => {
  const { data } = props;
  const [menu, setMenu] = useState([]);

  const memoizeMenu = useMemo(() => renderMenuItems(menu), [menu]);

  useEffect(() => {
    if (!data) return undefined;
    setMenu(data);
  }, []);

  return (
    <NavbarWrapper>
      <CssNavBarShadow />
      <CssNavBar>
        <LogoPorto />
        <CssAppName>{props.name}</CssAppName>
        <CssLinkWrapper>
          <CssUl>{memoizeMenu}</CssUl>
        </CssLinkWrapper>

        {props.children}
      </CssNavBar>
    </NavbarWrapper>
  );
};

export default NavBar;
