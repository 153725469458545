import React, { useState, useEffect } from 'react';
import {
  CssWrapper,
  CssGridWrapper,
  CssGraphWrapper,
  CssTotalWrapper
} from '../Global-style';

import Tile from '@apd/tile';
import { T_Body } from '@apd/typography';
import { PageHeader } from '@apd/page-header';
import Dropdown from '@apd/dropdown';
import config from '../../constants/config';

import { ResultTotalsGraph } from '../../components/graphs/ResultTotalsGraph';
import { TotalKpi } from '../../components/TotalKpi';
import { MapTotal } from '../../components/MapTotal';

const data = config.importJson.resultados;

const ResultadosScreen = () => {
 
  const [categories, setCategories] = useState(Object.keys(data));
  const [selectCategories, setSelectCategories] = useState(null);

  const [questions, setQuestions] = useState([]);
  const [selectQuestions, setSelectQuestions] = useState(null);

  const [answers, setAnswers] = useState([]);
  const [selectAnswer, setSelectAnswer] = useState(null);

  const [buildData, setBuildData] = useState(null);

  useEffect(() => {
    if (!selectCategories) return;
    setQuestions(Object.keys(data[selectCategories]));
  }, [selectCategories]);

  useEffect(() => {
    if (!selectCategories || !selectQuestions) return;
    setAnswers(Object.keys(data[selectCategories][selectQuestions]));

    data[selectCategories][selectQuestions] &&
      setBuildData(data[selectCategories][selectQuestions]);
  }, [selectQuestions]);

  const onClick_categories = (e) => setSelectCategories(e.value);
  const onClick_questions = (e) => setSelectQuestions(e.value);
  const onClick_answer = (e) => setSelectAnswer(e.value);

  return (
    <CssWrapper>
      <PageHeader pageTitle='Resultados' />

      <Dropdown
        labelInfo='Categorias'
        list={categories.map((e) => ({ value: e, description: e }))}
        onEvent={onClick_categories}
      />

      {selectCategories && (
        <Dropdown
          key={'questions' + JSON.stringify(questions)}
          labelInfo='Questões'
          list={questions.map((e) => ({ value: e, description: e }))}
          onEvent={onClick_questions}
        />
      )}

      <CssGridWrapper>
        <Tile header={selectCategories}>
          <T_Body>
            {selectCategories ? config.text.resultados[selectCategories].text : ""}</T_Body>
        </Tile>

        <Tile header='Total'>
          <ResultTotalsGraph 
            data={buildData} 
            question={selectQuestions}
          />
        </Tile>
      </CssGridWrapper>

      <CssGraphWrapper>
        <Tile header={selectQuestions}>
          <Dropdown
            key={'answer' + JSON.stringify(selectQuestions) + answers}
            labelInfo={'Respostas'}
            list={answers.map((e) => ({ value: e, description: e }))}
            onEvent={onClick_answer}
          />
          <MapTotal 
            data={buildData} 
            answer={selectAnswer} 
            question={selectQuestions}
          />
          <CssTotalWrapper>
            <TotalKpi
              key={'TotalKpi' + JSON.stringify(selectQuestions) + answers}
              data={buildData}
              answer={selectAnswer}
              question={selectQuestions}
            />
          </CssTotalWrapper>
        </Tile>
      </CssGraphWrapper>
    </CssWrapper>
  );
};

export default ResultadosScreen;
