import React, { Suspense } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import SociodemograficaScreen from '../screens/SociodemograficaScreen/SociodemograficaScreen'
import ResultadosScreen from '../screens/ResultadosScreen/ResultadosScreen'

const Router = () => (
  <>
    <Suspense fallback={<div>Loading...</div>}></Suspense>
    <Switch>
      <Route exact path='/resultados' component={ResultadosScreen} />
      <Route exact path='/sociodemografia' component={SociodemograficaScreen} />
      <Redirect from='/' to='/sociodemografia' />
    </Switch>
  </>
);

export default Router;
